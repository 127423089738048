import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, createTheme, CircularProgress, Box, Autocomplete, TableHead, Table, TableBody, TableCell, TableRow, Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import translations from './config/translations.json';
import TextField from '@mui/material/TextField';
import config from './config/env.json';
import { getCookieValue, setCookieValue } from './App';
import { NumericFormat } from 'react-number-format';
import { getCurrencySymbol } from './Util_currencies';
import { fetchBankTransferFee } from './Util_API_calls';
import { sendOTPDoubleAuth, verifyOTPDoubleAuth } from './Util_OTP_doubleauth';
import { fetchBankAccountBalance } from './Util_API_calls';
import { number_to_italian_currency, italian_currency_to_number } from './Util_format';
import { t } from './Util_format';
import Modal_Waiting_Push from './Modal_Waiting_Push';
import { get_api_url } from './Util_API_calls';

function Modal_StartBankTransfer({ isOpen, onClose, beneficiaries, onSave, closeModal, url_beneficiary_id, UserAccountNumberFrom, UserAccountIDFrom, tempBeneficiary, setTransfering }) {

  const [ModalStepNumber, setModalStepNumber] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isProcessing, setIsProcessing] = useState(false);
  const inputsRef = useRef([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const step2_submitButtonRef = useRef(null);
  const step4_submitButtonRef = useRef(null);
  const DoubleAuthPaymentCreateExp = getCookieValue('DoubleAuthPaymentCreateExp');
  const navigate = useNavigate();
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [transferAmount, setTransferAmount] = useState('');
  const [transferDescription, setTransferDescription] = useState('');
  const amountInputRef = useRef(null);
  const descriptionInputRef = useRef(null);
  const [bank_transfer_fee, setbank_transfer_fee] = useState(null);
  const [bank_account_balance, setbank_account_balance] = useState(null);
  const [shouldSaveBeneficiary, setSaveBeneficiary] = useState(false);
  const [popCode, setPopCode] = useState(null);
  const beneficiaryAddressRef = useRef(null);
  const onSaveBeneficiaryChange = (event) => {
    setSaveBeneficiary(event.target.checked);
  };
  const isFeeBeingCalculated = bank_transfer_fee === null;
  const hasInsufficientFunds = bank_transfer_fee !== null && bank_account_balance !== null && (bank_account_balance - italian_currency_to_number(transferAmount) - bank_transfer_fee) < 0;

  const styles = {
    otpInputsContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%'
    },
    otpInput: {
      textAlign: 'center',
      width: '2ch'
    },
    step_description: {
      paddingBottom: '20px'
    },
  };

  const onPopCodeChange = (event) => {
    const { value } = event.target;
    setPopCode(value);
  };

  const handleRedirectToCreateNewBeneficiary = () => {
    closeModal();
    navigate('/bank-transfers/create-beneficiary');
  };

  const step2_set_transfer_details = async () => {
    // console.log('ok');
    if (selectedBeneficiary) {
      setErrorMessage("");
      setSuccessMessage("");
      const token = getCookieValue("firebaseToken");
      setModalStepNumber(2);
    } else {
      closeModal();
      navigate("/bank-transfers/new");
    }
  };

  const step3_review_transfer_details = async () => {
    if (
      selectedBeneficiary.payment_type === "SWIFT" && !selectedBeneficiary.beneficiary_address
    ) {
      try {
        const API_URL = await get_api_url();
        const token = getCookieValue("firebaseToken");
        const response = await fetch(
          `${API_URL}/api/baas/utilities/iban_check`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              iban: selectedBeneficiary.beneficiary_iban,
            }),
          }
        );
        if (!response.ok) {
          const errorBody = await response.json();
          throw {
            response,
            message:
              errorBody.message || `HTTP error! status: ${response.status}`,
          };
        }
        const data = await response.json();
        beneficiaryAddressRef.current = data.data.bank.address;
      } catch (e) {
        setErrorMessage(t("error_fetching_data"));
        setSuccessMessage("");
        setbank_transfer_fee(null);
      }
    }

    // Validate the amount and description from the input fields
    const numericAmount = italian_currency_to_number(
      amountInputRef.current?.value
    );
    if (isNaN(numericAmount) || numericAmount <= 0) {
      // console.log(numericAmount);
      setErrorMessage(t("amount_is_less_than_zero"));
      setSuccessMessage("");
      amountInputRef.current?.focus();
      setTransferAmount("");
      return;
    } else {
      setTransferAmount(amountInputRef.current.value);
    }

    setTransferAmount(amountInputRef.current.value);
    const descriptionValue = descriptionInputRef.current?.value;
    if (!descriptionValue || descriptionValue.length < 5) {
      setErrorMessage(t("description_must_be_at_least_5_chars_long"));
      setSuccessMessage("");
      descriptionInputRef.current?.focus();
      setTransferDescription("");
      return;
    } else {
      setTransferDescription(descriptionValue);
    }

    setTransferDescription(descriptionValue);
    setErrorMessage("");
    setSuccessMessage("");
    setModalStepNumber(3);

    const clean_amount = numericAmount.toFixed(2);

    // calculate bank transfer fee
    try {
      const fee = await fetchBankTransferFee(
        selectedBeneficiary.payment_type === "SWIFT" ? "SWIFT" : "SEPA",
        UserAccountNumberFrom,
        selectedBeneficiary.beneficiary_iban,
        clean_amount,
        selectedBeneficiary.beneficiary_currency,
        "EXPRESS",
        selectedBeneficiary.bank_bic,
        selectedBeneficiary.bank_country_code
      );
      setbank_transfer_fee(fee);
    } catch (error) {
      // If there's an error in fetching fee or balance, show an error message and reset the state
      setErrorMessage(t("error_fetching_data"));
      setSuccessMessage("");
      setbank_transfer_fee(null);
    }

    try {
      // Fetch bank account balance
      const [balance] = await Promise.all([
        fetchBankAccountBalance(UserAccountIDFrom),
      ]);
      console.log(balance);
      // Update the state with the fetched data
      setbank_account_balance(balance);
    } catch (error) {
      // If there's an error in fetching fee or balance, show an error message and reset the state
      setErrorMessage(t("error_fetching_data"));
      setSuccessMessage("");
      setbank_account_balance(null);
    }
  }

  const step4_handleSendOTPDoubleAuth = async (isForceSms) => {
    console.log(isForceSms);
    setIsProcessing(true);
    if (DoubleAuthPaymentCreateExp) {
      const expirationDate = new Date(DoubleAuthPaymentCreateExp);
      const currentDate = new Date();
      const difference = expirationDate - currentDate;
      const minutesLeft = Math.round(difference / 60000);
      if (minutesLeft > 0) {
        step5_handlebanktranfer();
        return
      }
    }

    setErrorMessage('');
    setSuccessMessage('');

    const token = getCookieValue('firebaseToken');
    try {
      const response = await sendOTPDoubleAuth('baas_accounts_payment_create', isForceSms);
      setModalStepNumber(4);
    } catch (error) {
      let errorMsg;
      switch (error.message) {
        case "too_many_requests_try_later":
          errorMsg = t("too_many_requests_try_later");
          break;
        case "wrong_otp_code":
          errorMsg = t("wrong_otp_code");
          break;
        default:
          errorMsg = t(error.message);
          break;
      }
      setErrorMessage(errorMsg);
      setSuccessMessage('');
      setIsProcessing(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const step5_handleVerifyOTPDoubleAuth = async () => {
    setTransfering(true);
    setErrorMessage('');
    setSuccessMessage('');
    setIsProcessing(true);
    const token = getCookieValue('firebaseToken');

    try {
      const response = await verifyOTPDoubleAuth('baas_accounts_payment_create', otp.join(''), token);
      setModalStepNumber(5);
      setIsProcessing(true);
      step5_handlebanktranfer();
    } catch (error) {
      const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
      setErrorMessage(errorMsg);
      setIsProcessing(false);
      setSuccessMessage('');
    } finally {
      setIsProcessing(true);
    }
  };

  const step5_handlebanktranfer = async (isTestAction = false) => {
    const API_URL = await get_api_url();
    if (!isTestAction) {
      setErrorMessage('');
      setSuccessMessage('');
      setIsProcessing(true);
    }
    const token = getCookieValue('firebaseToken');

    try {
      setCookieValue('DoubleAuthPaymentCreateExp', new Date(Date.now())); // make the auth expire

      const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');
      const data = {
        payment_type: selectedBeneficiary.payment_type,
        from: UserAccountNumberFrom,
        to: selectedBeneficiary.beneficiary_iban,
        amount: italian_currency_to_number(transferAmount).toFixed(2),
        currency: selectedBeneficiary.beneficiary_currency,
        description: transferDescription,
        priority: RETAIL_OR_BUSINESS === 'BUSINESS' ? 'INSTANT' : 'express',
        beneficiary_name: selectedBeneficiary.beneficiary_name + ' ' + selectedBeneficiary.beneficiary_surname,
        beneficiary_country: selectedBeneficiary.bank_country_code,
        beneficiary_bank_code: selectedBeneficiary.bank_bic.length === 8 ? `${selectedBeneficiary.bank_bic}XXX` : selectedBeneficiary.bank_bic
      };
      if (popCode) {
        data.pop = popCode;
      }
      if (
        selectedBeneficiary.beneficiary_address ||
        beneficiaryAddressRef.current
      ) {
        data.beneficiary_address = selectedBeneficiary.beneficiary_address
          ? selectedBeneficiary.beneficiary_address
          : beneficiaryAddressRef.current;
      }
      if (RETAIL_OR_BUSINESS === 'BUSINESS') {
        data.business_id = getCookieValue("business_id");
      }
      const body = JSON.stringify(data);

      const response = await fetch(`${API_URL}/api/baas/transfer/send_payment`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: body
      });

      // console.log(response);
      if (!response.ok) {
        if (isTestAction) {
          return;
        }
        setCookieValue('DoubleAuthPaymentCreateExp', new Date(Date.now() + 10 * 60000));
        if (response.status === 500) {
          throw new Error("unexpected_error");
        }
        if (response.status === 429) {
          throw new Error("pleasewait30secs");
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      if (isTestAction) {
        setModalStepNumber(5);
      }
      setSuccessMessage(t('transfer_initiated_successfully'));
      if (tempBeneficiary && shouldSaveBeneficiary) {
        try {
          const requestOptions = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${token}`,
            },
            body: new URLSearchParams({
              ...tempBeneficiary,
              beneficiary_type: "individual",
            }),
          };
          fetch(`${API_URL}/api/baas/beneficiaries/create`, requestOptions);
        } catch (_) { }
      }
    } catch (error) {
      const errorMsg = error.message === "too_many_requests_try_later" ? t("too_many_requests_try_later") : t(error.message);
      setErrorMessage(errorMsg);
      setIsProcessing(false);
      setSuccessMessage('');
    } finally {
      // This will run regardless of whether the try block succeeds or an error occurs.
      setIsProcessing(false);
    }
  };

  const step4_handleBackspaceOnSubmit = (e) => {
    if (e.key === 'Backspace') {
      const lastInputIndex = otp.length - 1;
      inputsRef.current[lastInputIndex].focus();
      e.preventDefault();
    }
  };

  const handleChange = (index, value) => {
    if (ModalStepNumber !== 2 && ModalStepNumber !== 4) return;
    if (!/^[0-9]$/.test(value)) return;
    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });
    setTimeout(() => {
      if (index < otp.length - 1) {

        inputsRef.current[index + 1] && inputsRef.current[index + 1].focus();
      } else if (index === otp.length - 1 && value) {

        if (ModalStepNumber === 2) {
          step2_submitButtonRef.current && step2_submitButtonRef.current.focus();
        }
        if (ModalStepNumber === 4) {
          step4_submitButtonRef.current && step4_submitButtonRef.current.focus();
        }
      }
    }, 10);
  };

  const handleKeyDown = (e, index) => {
    if (ModalStepNumber !== 2 && ModalStepNumber !== 4) return;
    if (e.key === 'Backspace') {
      e.preventDefault();
      if (otp[index] === "") {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          setOtp((prevOtp) => {
            const newOtp = [...prevOtp];
            newOtp[prevIndex] = "";
            return newOtp;
          });
          setTimeout(() => {
            inputsRef.current[prevIndex].focus();
          }, 0);
        }
      } else {
        setOtp((prevOtp) => {
          const newOtp = [...prevOtp];
          newOtp[index] = "";
          return newOtp;
        });

        setTimeout(() => {
          inputsRef.current[index].focus();
        }, 0);
      }
    }
  };

  // Add a new useEffect hook for redirecting if no beneficiaries are present
  useEffect(() => {
    if (beneficiaries.length === 0) {
      closeModal();
      navigate('/bank-transfers/create-beneficiary');
    }
  }, [beneficiaries]); // Depend on beneficiaries to run when it changes

  useEffect(() => {
    if (ModalStepNumber === 4 && inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }
  }, [ModalStepNumber, DoubleAuthPaymentCreateExp, inputsRef]);

  useEffect(() => {
    if (url_beneficiary_id && beneficiaries.length > 0) {
      const matchingBeneficiary = beneficiaries.find(b => b.beneficiary_id === url_beneficiary_id);
      setSelectedBeneficiary(matchingBeneficiary || null);
      setModalStepNumber(2);
    }
  }, [url_beneficiary_id, beneficiaries]);

  useEffect(() => {
    if (tempBeneficiary) {
      setSelectedBeneficiary(tempBeneficiary);
      setModalStepNumber(2);
    }
  }, [tempBeneficiary]);

  switch (ModalStepNumber) {
    case 2:
      if (!selectedBeneficiary) return <div>{t('no_beneficiary_selected')}</div>;
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="modal_title">
              {t("set_bank_transfer_details")}
            </DialogTitle>
            <DialogContent>
              {errorMessage && (
                <div className="error-container_modal">
                  <div className="error-icon">!</div>
                  <Typography className="error-text">{errorMessage}</Typography>
                </div>
              )}
              {successMessage && (
                <div className="success-container_modal">
                  <div className="success-icon">✓</div>
                  <Typography className="success-text">
                    {successMessage}
                  </Typography>
                </div>
              )}
              <Table style={{ width: "100%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("beneficiary_name")}:{" "}
                      {selectedBeneficiary.beneficiary_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("beneficiary_surname")}:{" "}
                      {selectedBeneficiary.beneficiary_surname}
                    </TableCell>
                  </TableRow>
                  {selectedBeneficiary.beneficiary_email && (
                    <TableRow>
                      <TableCell className="table_body">
                        {t("Email")}: {selectedBeneficiary.beneficiary_email}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className="table_body">
                      {t("beneficiary_iban")}:{" "}
                      {selectedBeneficiary.beneficiary_iban
                        .match(/.{1,4}/g)
                        .join(" ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("beneficiary_payment_type")}:{" "}
                      {selectedBeneficiary.payment_type}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("bank_bic")}: {selectedBeneficiary.bank_bic}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="table_body"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <NumericFormat
                        value={transferAmount}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        isNumericString={true}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                        }}
                        suffix={
                          " " +
                          getCurrencySymbol(
                            selectedBeneficiary.beneficiary_currency
                          )
                        }
                        customInput={TextField}
                        label={t("amount")}
                        variant="outlined"
                        name="transfer_amount"
                        decimalScale={2}
                        fullWidth
                        inputRef={amountInputRef}
                        allowNegative={false}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      <TextField
                        label={t("description")}
                        variant="outlined"
                        fullWidth
                        inputRef={descriptionInputRef}
                        onKeyPress={(event) => {
                          const allowedCharacters = /^[a-zA-Z0-9 _-]*$/;

                          if (
                            event.target.value.length >= 100 &&
                            event.key !== "Backspace"
                          ) {
                            event.preventDefault();
                          } else if (
                            !allowedCharacters.test(event.key) &&
                            event.key !== "Backspace"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  {(selectedBeneficiary.beneficiary_iban?.startsWith("AE") ===
                    true ||
                    selectedBeneficiary.beneficiary_iban?.startsWith("BH") ===
                      true) && (
                    <TableRow>
                      <TableCell className="table_body">
                        <TextField
                          select
                          label={t("purpose_of_payment")}
                          name="popCode"
                          fullWidth
                          margin="dense"
                          value={popCode}
                          onChange={onPopCodeChange}
                        >
                          {Object.keys(swiftPOPCodes).map((e) => (
                            <MenuItem key={e} value={e}>
                              {swiftPOPCodes[e]}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={step3_review_transfer_details}
                variant="contained"
                color="primary"
              >
                {t("next_button")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    case 3:
      if (!selectedBeneficiary) return <div>{t('no_beneficiary_selected')}</div>;
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className="modal_title">
              {t("review_bank_transfer_details")}
            </DialogTitle>
            <DialogContent>
              {errorMessage && (
                <div className="error-container_modal">
                  <div className="error-icon">!</div>
                  <Typography className="error-text">{errorMessage}</Typography>
                </div>
              )}
              {successMessage && (
                <div className="success-container_modal">
                  <div className="success-icon">✓</div>
                  <Typography className="success-text">
                    {successMessage}
                  </Typography>
                </div>
              )}
              <Table style={{ width: "100%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("beneficiary_name")}:{" "}
                      {selectedBeneficiary.beneficiary_surname}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("beneficiary_surname")}:{" "}
                      {selectedBeneficiary.beneficiary_surname}
                    </TableCell>
                  </TableRow>
                  {selectedBeneficiary.beneficiary_email && (
                    <TableRow>
                      <TableCell className="table_body">
                        {t("Email")}: {selectedBeneficiary.beneficiary_email}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className="table_body">
                      {t("beneficiary_iban")}:{" "}
                      {selectedBeneficiary.beneficiary_iban
                        .match(/.{1,4}/g)
                        .join(" ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("beneficiary_payment_type")}:{" "}
                      {selectedBeneficiary.payment_type}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("transfer_amount")}: {transferAmount}
                    </TableCell>{" "}
                    {/* Display the saved transfer amount */}
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      {t("transfer_description")}: {transferDescription}
                    </TableCell>{" "}
                    {/* Display the saved transfer description */}
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      <span>{t("bank_transfer_fee")}: </span>
                      {bank_transfer_fee === null ? (
                        <CircularProgress
                          style={{ marginLeft: "10px", alignSelf: "center" }}
                          size={14}
                        />
                      ) : (
                        <span>
                          {number_to_italian_currency(bank_transfer_fee)}
                        </span>
                      )}
                    </TableCell>{" "}
                    {/* Display the saved transfer description */}
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      <span>{t("available_balance")}: </span>
                      {bank_account_balance === null ? (
                        <CircularProgress
                          style={{ marginLeft: "10px", alignSelf: "center" }}
                          size={14}
                        />
                      ) : (
                        <span>
                          {" "}
                          {number_to_italian_currency(bank_account_balance)}
                        </span>
                      )}
                    </TableCell>{" "}
                    {/* Display the saved transfer description */}
                  </TableRow>
                  <TableRow>
                    <TableCell className="table_body">
                      <span>{t("residual_balance_post_transfer")}: </span>
                      {bank_account_balance === null ? (
                        <CircularProgress
                          style={{ marginLeft: "10px", alignSelf: "center" }}
                          size={14}
                        />
                      ) : (
                        <span
                          style={{
                            color:
                              bank_account_balance -
                                italian_currency_to_number(transferAmount) -
                                bank_transfer_fee <
                                0
                                ? "red"
                                : "inherit",
                          }}
                        >
                          {number_to_italian_currency(
                            bank_account_balance -
                            italian_currency_to_number(transferAmount) -
                            bank_transfer_fee
                          )}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
              {tempBeneficiary && (
                <FormControlLabel
                  control={<Checkbox />}
                  label={t("Save beneficiary")}
                  checked={shouldSaveBeneficiary}
                  onChange={onSaveBeneficiaryChange}
                  style={{ marginLeft: 1 }}
                />
              )}
              {!tempBeneficiary && <div />}
              <div style={{ display: "flex", direction: "row" }}>
                <Button
                  onClick={step2_set_transfer_details}
                  variant="contained"
                  color="primary"
                  disabled={isProcessing}
                >
                  {t("edit_button")}
                </Button>
                <Box sx={{ mx: 0.5 }} />
                <Button
                  onClick={
                    DoubleAuthPaymentCreateExp &&
                      new Date(DoubleAuthPaymentCreateExp) > new Date()
                      ? step5_handlebanktranfer
                      : () => step4_handleSendOTPDoubleAuth(false)
                  }
                  variant="contained"
                  color="primary"
                  disabled={
                    isFeeBeingCalculated || hasInsufficientFunds || isProcessing
                  }
                >
                  {isFeeBeingCalculated ? (
                    <>
                      <div style={{ paddingRight: "10px" }}>
                        {t("calculating_fee")}
                      </div>
                      <CircularProgress size={20} color="inherit" />
                    </>
                  ) : isProcessing ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : hasInsufficientFunds ? (
                    t("not_enough_funds")
                  ) : DoubleAuthPaymentCreateExp &&
                    new Date(DoubleAuthPaymentCreateExp) > new Date() ? (
                    t("send_money")
                  ) : (
                    t("send_verification_sms")
                  )}
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </>
      );

    case 4:
      return getCookieValue("double_auth_otp_delivery_method") === "push" ? (
        <Modal_Waiting_Push
          onClose={onClose}
          errorMessage={errorMessage}
          isProcessing={isProcessing}
          onForceSendSMS={() => step4_handleSendOTPDoubleAuth(true)}
          onAction={() => step5_handlebanktranfer(true)}
        />
      ) : (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='modal_title'>
              {t('confirm_code')}
            </DialogTitle>
            <DialogContent>
              {errorMessage && (
                <div className="error-container_modal">
                  <div className="error-icon">!</div>
                  <Typography className="error-text">{errorMessage}</Typography>
                </div>
              )}
              {successMessage &&
                <div className="success-container_modal">
                  <div className="success-icon">✓</div>
                  <Typography className="success-text">{successMessage}</Typography>
                </div>
              }
              <div style={styles.step_description}>
                {t('please_enter_6_digit_otp_received_via_sms')}
              </div>
              <div className="otpInputs" style={styles.otpInputsContainer}>
                {otp.map((digit, index) => (
                  <TextField
                    key={index}
                    variant="outlined"
                    inputProps={{
                      maxLength: 1,
                      style: styles.otpInput
                    }}
                    inputRef={el => inputsRef.current[index] = el}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => step4_handleSendOTPDoubleAuth(false)}
                variant="contained"
                color="primary"
                disabled={isProcessing}
              >
                {t('resend_sms')}
              </Button>
              <Button
                ref={step4_submitButtonRef}
                onKeyDown={step4_handleBackspaceOnSubmit}
                onClick={step5_handleVerifyOTPDoubleAuth}
                variant="contained"
                color="primary"
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('verify_otp_and_send_money')
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    case 5:
      return (
        // close modal
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='modal_title'>
              {t('bank_transfer_status')}
            </DialogTitle>
            <DialogContent>
              {errorMessage && (
                <div className="error-container_modal">
                  <div className="error-icon">!</div>
                  <Typography className="error-text">{errorMessage}</Typography>
                </div>
              )}
              {successMessage &&
                <div className="success-container_modal">
                  <div className="success-icon">✓</div>
                  <Typography className="success-text">{successMessage}</Typography>
                </div>
              }

              {isProcessing ? (
                <div style={styles.step_description}>
                  {t('transfer_in_progress_do_not_close_this_window')}
                </div>
              ) : (
                <div style={styles.step_description}>
                  {t('you_can_close_this_window')}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                ref={step4_submitButtonRef}
                onClick={closeModal}
                variant="contained"
                color="primary"
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('close')
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    default:
      return (
        <>
          <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='modal_title'>
              {t('set_transfer_beneficiary')}
            </DialogTitle>
            <DialogContent>
              {errorMessage && (
                <div className="error-container_modal">
                  <div className="error-icon">!</div>
                  <Typography className="error-text">{errorMessage}</Typography>
                </div>
              )}
              {successMessage &&
                <div className="success-container_modal">
                  <div className="success-icon">✓</div>
                  <Typography className="success-text">{successMessage}</Typography>
                </div>
              }

              <div style={styles.step_description}>
                {t('please_select_or_create_beneficiary_description')}
              </div>
              <Box sx={{ width: 300 }}>
                <Autocomplete
                  id="beneficiary-autocomplete"
                  options={beneficiaries}
                  getOptionLabel={(option) => `${option.beneficiary_name} ${option.beneficiary_surname} - ${option.beneficiary_iban.slice(-4)}`}
                  getOptionSelected={(option, value) => option.beneficiary_id === value.beneficiary_id}
                  value={selectedBeneficiary}
                  onChange={(event, newValue) => {
                    setSelectedBeneficiary(newValue);
                    // console.log(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t ? t('select_beneficiary') : 'Please start typing the name'}
                      variant="outlined"
                    />
                  )}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleRedirectToCreateNewBeneficiary}
                variant="contained"
                color="primary"
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t('create_beneficiary')
                )}
              </Button>

              <Button
                onClick={step2_set_transfer_details}
                variant="contained"
                color="primary"
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t(selectedBeneficiary ? 'next_button' : 'new_bank_transfer')
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
  }
}

export default Modal_StartBankTransfer;

const swiftPOPCodes = {
  "ACM": "Agency commission",
  "AES": "Advance payment against EOS",
  "AFA":
      "Receipts or payments from personal residents bank accounts or deposits abroad",
  "AFL":
      "Receipts or payments from personal non-resident bank accounts in the UAE",
  "ALW": "Allowance",
  "ATS": "Air transport",
  "BON": "Bonus",
  "CCP": "Corporate card payments",
  "CEA":
      "Equity for establishment of new companies from residents abroad, equity of merger or acquisition of companies abroad from residents and participation to capital increase of related company abroad",
  "CEL":
      "Equity and investment fund shares for the establishment of new companies in the UAE from nonresidents, equity of merger or acquisition of companies in the UAE from nonresidents and participation to capital increase of related companies from non-residents in the UAE",
  "CHC": "Charitable contributions",
  "CIN": "Commercial investments",
  "COM": "Commission",
  "COP": "Compensation",
  "CRP": "Credit card payment",
  "DCP": "Prepaid reloadable personalized debit card payments",
  "DIV": "Dividend payouts",
  "DLA":
      "Purchases and sales of foreign debt securities in not related companies – more than a year",
  "DLF":
      "Debt instruments intragroup loans, foreign deposits (above 10% share)",
  "DLL":
      "Purchases and sales of securities issued by residents in not related companies – more than a year",
  "DOE": "Dividends on equity not intra group",
  "DIF": "Debt instruments intragroup loans securities foreign deposits",
  "DIL": "Debt instruments intragroup loans securities deposits in the UAE",
  "DSA":
      "Purchases and sales of foreign debt securities less than a year in the related company",
  "DSF": "Debt instruments intragroup foreign securities",
  "DSL":
      "Purchases and sales of securities issued by residents less than a year in the related companies",
  "EDU": "Educational support",
  "EMI": "Equated monthly installments",
  "EOS": "End of service/ Final settlement",
  "FAM": "Family support (workers' remittances)",
  "FDA": "Financial derivatives foreign",
  "FDL": "Financial derivatives in the UAE",
  "FIA": "Investment fund shares foreign",
  "FIL": "Investment fund shares in the UAE",
  "FIS": "Financial services",
  "FSA": "Equity other than investment fund shares in related companies abroad",
  "FSL":
      "Equity other than investment fund shares in related companies in the UAE",
  "GDE": "Goods sold (exports in fob value)",
  "GDI": "Goods bought (imports in cif value)",
  "GMS": "Processing repair and maintenance services on goods",
  "GOS": "Government goods and services embassies, etc.",
  "GRI": "Government-related income taxes, tariffs, capital transfers, etc.",
  "IFS": "Information services",
  "IGD": "Dividends intragroup",
  "IGT": "Intergroup transfer",
  "IID": "Interest on debt intragroup",
  "INS": "Insurance services",
  "IOD": "Income on deposits",
  "IOL": "Income on loans",
  "IPC": "Charges for the use of intellectual property royalties",
  "IPO": "IPO subscriptions",
  "IRP": "Interest rate swap payments",
  "IRW": "Interest rate unwind payments",
  "ISH": "Income on investment funds shares",
  "ISL": "Interest on securities more than a year",
  "ISS": "Interest on securities less than a year",
  "ITS": "Computer services",
  "LAS": "Leave salary",
  "LDL":
      "Debt instruments intragroup loans, deposits in the UAE (above 10% share)",
  "LDS": "Debt instruments intragroup securities in the UAE",
  "LEA": "Leasing abroad",
  "LEL": "Leasing in the UAE",
  "LIP": "Loan interest payments",
  "LLA":
      "Loan drawings or repayments on loans extended to non-residents – long-term",
  "LLL": "Drawings or repayments on foreign loans to residents – long-term",
  "LNC": "Loan charges",
  "LND": "Loan disbursements",
  "MCR": "Monetary claim reimbursement",
  "MWI": "Mobile wallet card cash-in",
  "MWO": "Mobile wallet card cash-out",
  "MWP": "Mobile wallet card payments",
  "OAT": "Own account transfer",
  "OTS": "Other modes of transport",
  "OVT": "Overtime",
  "PEN": "Pension",
  "PIN": "Personal investments",
  "PIP": "Profits on Islamic products",
  "PMS": "Professional and management consulting services",
  "POR": "Refunds/reversals on IPO subscriptions",
  "POS": "POS merchant settlement",
  "PPA": "Purchase of real estate abroad from residents",
  "PPL": "Purchase of real estate in the UAE from nonresidents",
  "PRP": "Profit rate swap payments",
  "PRR": "Profits or rents on real estate",
  "PRS": "Personal cultural audio visual and recreational services",
  "PRW": "Profit rate unwind payments",
  "RDA": "Reverse debt instruments abroad",
  "RDL": "Reverse debt instruments in the UAE",
  "RDS": "Research and development services",
  "REA": "Reverse equity share abroad",
  "REL": "Reverse equity share in the UAE",
  "RFS": "Repos on securities issued by residents",
  "RNT": "Rent payments",
  "SAA": "Salary advance",
  "SAL": "Salary",
  "SCO": "Construction",
  "SLA":
      "Loan drawings or repayments on loans extended to nonresidents – short-term",
  "SLL":
      "Loan drawings or repayments on foreign loans extended to residents – short-term",
  "STR": "Travel",
  "STS": "Sea transport",
  "SVI": "Stored value card cash-In",
  "SVO": "Stored value card cash-out",
  "SVP": "Stored value card payments",
  "TCP": "Trade credits and advances payable",
  "TCR": "Trade credits and advances receivable",
  "TCS": "Telecommunication services",
  "TKT": "Tickets",
  "TOF": "Transfer of funds between persons normal and juridical",
  "TTS": "Technical trade related and other business services",
  "UFP": "Unclaimed funds placement",
  "UTL": "Utility bill payments"
};
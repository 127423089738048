import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import SumsubWebSdk from "@sumsub/websdk-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookieValue } from "./App";
import config from "./config/env.json";
import "./css/Unauthenticated.css";
import Loading from "./Loading";

function Kyc() {
  const [accessToken, setAccessToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null); // Added state for email
  const firebaseToken = getCookieValue("firebaseToken");
  const navigate = useNavigate();
  const isPassedOtp = getCookieValue("login_otp_completed") === "true";

  useEffect(() => {
    async function fetchAccessToken() {
      try {
        const response = await fetch(
          `${config.API_DOMAIN_RETAIL}/api/baas/kyc/issue-token`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + firebaseToken,
            },
          }
        );

        if (response.status === 401) {
          navigate("/login?action=clean-cookie&reason=session_expired");
          return;
        }

        const data = await response.json();
        if (!response.ok) {
          if (data.fdt_error_code === "APPLICANT_DEACTIVATED_BY_SUMSUB") {
            navigate("/login?action=clean-cookie&reason=account-banned");
            return;
          }
          throw new Error("Network response was not ok");
        }

        if (data.userId && data.userId.trim().length > 0) {
          setAccessToken(data.token);
          setUserEmail(data.email); // Set the email state with the email from the API response
        } else {
          await new Promise((r) => setTimeout(r, 2000));
          fetchAccessToken();
        }
      } catch (error) {
        console.error("There was a problem fetching the access token:", error);
      }
    }

    fetchAccessToken();
  }, [firebaseToken, navigate]);

  function handleMessage(messageType, payload) {
    // console.log('onMessage', messageType, payload);
    if (payload && payload.applicantId) {
      console.log("Applicant ID:", payload.applicantId);
    }
  }

  const accessTokenExpirationHandler = () => {
    // console.log('Access token expired');
  };

  const handleLogout = () => {
    if (isPassedOtp) {
      navigate("/home");
    } else {
      navigate("/login?action=clean-cookie&reason=user_logout");
    }
  };

  return (
    <div className="kcy_main_div">
      <div className="kyc_content">
        <div className="kyc_web_sdk">
          {accessToken && userEmail ? ( // Also check for userEmail before rendering SumsubWebSdk
            <SumsubWebSdk
              accessToken={accessToken}
              expirationHandler={accessTokenExpirationHandler}
              config={{ email: userEmail }} // Pass the userEmail state here
              options={{ adaptIframeHeight: true }}
              onMessage={handleMessage}
              onError={(e) => console.log("onError", e)}
            />
          ) : (
            <Loading />
          )}
        </div>
        <div className="kyc_close_button">
          <IconButton onClick={handleLogout}>
            <CloseIcon color="primary" />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default Kyc;

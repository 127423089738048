import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "../Util_format";
import logopng from "../pics/logo.png";

function BusinessIntroduction() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        <div style={{ width: "100%" }}>
          <img src={logopng} alt={t("logo")} className="login_logo_mobile" />
        </div>
        {t("Apri il tuo conto aziendale")}
      </Typography>
      <Typography>{t("description_business_account")}</Typography>
      <Box sx={{ mt: 3 }} />
      <div style={{ textAlign: "left", overflow: "auto", maxHeight: "250px" }}>
        <Typography>{t("Nome dell'azienda:")}</Typography>
        <Typography>{t("Il nome ufficiale dell'azienda.")}</Typography>
        <Typography sx={{ mt: 2 }}>{t("Indirizzo dell'azienda:")}</Typography>
        <Typography>{t("L'indirizzo fisico dell'azienda.")}</Typography>
        <Typography sx={{ mt: 2 }}>{t("Tipo di attività:")}</Typography>
        <Typography>
          {t("Il settore o la natura delle operazioni aziendali.")}
        </Typography>
        <Typography sx={{ mt: 2 }}>{t("Data di costituzione:")}</Typography>
        <Typography>
          {t(
            "La data in cui l'azienda è stata ufficialmente registrata o costituita."
          )}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          {t("Numero di registrazione aziendale:")}
        </Typography>
        <Typography>
          {t(
            "L'identificatore unico assegnato all'azienda al momento della registrazione."
          )}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          {"• " +
            t("Estratto dalla Camera di Commercio non più vecchio di 6 mesi.")}
        </Typography>
        <Typography>{"• " + t("Struttura azionaria.")}</Typography>
        <Typography>{"• " + t("Statuto dell'associazione.")}</Typography>
        <Typography>
          {"• " + t("Identificazione di tutti gli azionisti.")}
        </Typography>
        <Typography>
          {"• " + t("Prova dell'indirizzo della società.")}
        </Typography>
        <Box sx={{ mt: 2 }} />
        <Typography sx={{ fontWeight: "bold" }}>
          {t("Il processo potrebbe richiedere fino a 4 giorni.")}
        </Typography>
      </div>
      <Box sx={{ mt: 3 }} />
      <TextField
        label={t("Nome Aziendale")}
        id="business_name"
        variant="outlined"
        margin="normal"
        fullWidth
      />
      {error && (
        <Typography className="error-text" style={{ textAlign: "left" }}>
          {error}
        </Typography>
      )}
      <Box sx={{ mt: 2 }} />
      <Button
        variant="contained"
        color="primary"
        className="unauthenticated_button"
        onClick={() => {
          const businessName = document.getElementById("business_name").value;
          if (!businessName.trim()) {
            setError(t("message_error_empty_business_name"));
            return;
          }
          navigate(`/kyb/new/${businessName}`);
        }}
      >
        {t("Iniziamo")}
      </Button>
    </div>
  );
}

export default BusinessIntroduction;
